import { useQuery } from "@tanstack/react-query";
import sdk from "~/client/sdk";
import { getDuration } from "~/utils/duration";

const QUERY_KEY = {
    USER_METADATA: "userMetadata",
    USER: "user",
};

export const useCheckUserSessionValidity = ({ isSidebarPage }: { isSidebarPage: boolean }) => {
    // Query to validate session
    const { data, isLoading, isError, isSuccess, refetch } = useQuery({
        queryKey: [QUERY_KEY.USER_METADATA],
        queryFn: async () => {
            const response = await sdk.clientAuthService.ping();
            return response.data;
        },
        enabled: isSidebarPage, // Only run query when email is set
        retry: 3, // Retry failed requests up to 3 times
        staleTime: 30000, // Consider data fresh for 30 seconds
        refetchOnWindowFocus: true, // Refetch when window regains focus
    });

    return { data, isLoading, isError, isSuccess, refetch };
};

export const useUser = () => {
    const { data, isLoading, isError, isSuccess, refetch } = useQuery({
        queryKey: [QUERY_KEY.USER],
        queryFn: () => sdk.clientAuthService.getUserInfo().then((res) => res.data),
        staleTime: getDuration({
            days: 1,
        }),
    });
    if (window.opener && data) {
        window.opener.postMessage(data, "*");
    }

    return {
        user: data,
        // @ts-expect-error, apiKey is not typed properly
        userEmail: data?.apiKey?.Member?.email,
        isLoading,
        isError,
        isSuccess,
        refetch,
    };
};

export const useApiKey = () => {
    const { user } = useUser();

    return user?.apiKey?.key || undefined;
};
