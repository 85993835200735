import { css } from "@emotion/css";

export const plainBlackBackgroundCSS = css`
    background: #f9f9f9;
`;

export const scrollBarStyle = css`
    scrollbar-color: #0000003e #76767620;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
        width: 8px;
    }
`;

export const linkCSS = css`
    color: #ebebebec;
    transition: color 0.18s linear;
    :hover {
        color: #5d78ff;
    }
`;

export const borderAndShadowCSS = css`
    border-width: 0.5px !important;
    box-shadow:
        lch(0 0 0 / 0.03) 0px 4px 4px -1px,
        lch(0 0 0 / 0.05) 0px 0px 1px 0px !important;
`;
