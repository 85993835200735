import { useMutation } from "@tanstack/react-query";
import { atom, useAtom } from "jotai";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { switchProjectAction } from "~/bootstap";
import backend from "~/client/sdk";
import { useToast } from "~/design-system/atom/use-toast";
import { useProjectsQuery } from "~/hooks/query/dashboard";
import { queryClient } from "~/queryClient";
import { LOCAL_STORAGE_KEYS, storage } from "~/utils/localStorage";

// Move to common state
export const selectProjectAtom = atom<string | null>(null);

// Move to common hooks
export const useProjects = () => {
    const { toast } = useToast();
    const router = useRouter();
    const { data: projectsData, isLoading, isError, isSuccess: isProjectsSuccess, refetch } = useProjectsQuery();

    const [selectedProject, setSelectedProject] = useAtom(selectProjectAtom);

    const selectedProjectItem = useMemo(
        () => projectsData?.items?.find((project) => project.id === selectedProject),
        [projectsData, selectedProject],
    );

    const createProject = useMutation({
        mutationFn: (name: string) => {
            return backend.clientAuthService.addProject({
                body: {
                    name,
                },
            });
        },
    });

    const deleteProject = useMutation({
        mutationFn: (id: string) => {
            return backend.clientAuthService.deleteProject({
                path: {
                    projectId: id,
                },
            });
        },
    });

    const findProjectName = (id: string) => {
        const project = projectsData?.items?.find((project) => project.id === id);
        return project?.name;
    };

    const switchProject = async (id: string) => {
        setSelectedProject(id);
        switchProjectAction(id);

        const projectName = findProjectName(id);

        toast({
            title: "Project switched",
            description: projectName ? `Switching to project: ${projectName}` : "Created a new project, Switching to it",
            variant: "success",
        });
        storage.set(LOCAL_STORAGE_KEYS.CURRENT_PROJECT, id);
        setTimeout(() => {
            if (router.asPath.includes("?")) {
                router.replace(router.pathname, undefined, { shallow: true });
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            } else {
                window.location.reload();
            }
        }, 200);
        window.projectId = id;
        const allQueries = queryClient.getQueryCache().findAll();
        queryClient.invalidateQueries({ queryKey: allQueries.map((query) => query.queryKey) });
    };

    useEffect(() => {
        if (!isProjectsSuccess) return;
        // Early return if no projects or if a project is already selected
        if (!projectsData?.items?.length || selectedProject) return;

        // Try to restore previously selected project from localStorage
        const currentProjectId = storage.get(LOCAL_STORAGE_KEYS.CURRENT_PROJECT, null);
        const currentProject = projectsData.items.find((project) => project.id === currentProjectId);

        // If we found the stored project, restore it and invalidate queries
        if (currentProjectId && currentProject) {
            setSelectedProject(currentProjectId);
            switchProjectAction(currentProjectId);
            window.projectId = currentProjectId;

            const allQueries = queryClient.getQueryCache().findAll();
            queryClient.invalidateQueries({
                queryKey: allQueries.map((query) => query.queryKey),
            });
            return;
        }

        // Otherwise select the oldest project (last in the list since sorted by createdAt desc)
        const oldestProject = projectsData.items[projectsData.items.length - 1];
        if (oldestProject) {
            setSelectedProject(oldestProject.id);
            switchProjectAction(oldestProject.id);
        }
    }, [isProjectsSuccess]);

    return {
        projects: projectsData?.items || [],
        isProjectsLoading: isLoading,
        isProjectsError: isError,
        isProjectsSuccess: isProjectsSuccess,
        refetchProjects: refetch,
        createProject,
        deleteProject,
        switchProject,
        selectedProject,
        selectedProjectItem,
    };
};
