import { atom, useAtom } from "jotai";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

export const queryParamsAtom = atom<Record<string, unknown>>({});

/**
 * Encode any JS values into query strings:
 *  - Objects/arrays are JSON.stringified
 *  - Primitives are cast to string
 *  - null/undefined are omitted
 */
function encodeQueryParams(params: Record<string, unknown>): Record<string, string> {
    const result: Record<string, string> = {};
    for (const [key, value] of Object.entries(params)) {
        if (value == null) {
            continue; // skip null or undefined
        }
        if (typeof value === "object") {
            // JSON-encode objects or arrays
            result[key] = JSON.stringify(value);
        } else {
            // Convert primitives to string
            result[key] = String(value);
        }
    }
    return result;
}

/**
 * Decode a query map back into JS values:
 *  - Try to JSON.parse each string; if it fails, leave as string
 *  - If it's an array, take the first item
 */
function decodeQueryParams(query: Record<string, string | string[] | undefined>): Record<string, unknown> {
    const result: Record<string, unknown> = {};

    for (const [key, rawVal] of Object.entries(query)) {
        if (rawVal === undefined) {
            continue;
        }
        const valStr = Array.isArray(rawVal) ? rawVal[0] : rawVal;
        try {
            result[key] = JSON.parse(valStr ?? "");
        } catch {
            result[key] = valStr;
        }
    }

    return result;
}

export function QuerySyncComponent() {
    const router = useRouter();
    const [queryParams, setQueryParams] = useAtom(queryParamsAtom);

    // Gate to prevent immediate "pull" from router after we "push" an update
    const isUpdatingFromAtomRef = useRef(false);

    // A) When the router changes (and we're not the cause), decode to the atom
    useEffect(() => {
        if (!router.isReady) return;
        if (isUpdatingFromAtomRef.current) return;

        const decoded = decodeQueryParams(router.query);
        if (JSON.stringify(decoded) !== JSON.stringify(queryParams)) {
            setQueryParams(decoded);
        }
    }, [router.isReady]);

    // B) When the atom changes, encode and push/replace router query
    useEffect(() => {
        if (!router.isReady) return;

        const currentDecoded = decodeQueryParams(router.query);
        if (JSON.stringify(currentDecoded) !== JSON.stringify(queryParams)) {
            isUpdatingFromAtomRef.current = true;
            router
                .replace(
                    {
                        pathname: router.pathname,
                        query: encodeQueryParams(queryParams),
                    },
                    undefined,
                    { shallow: true },
                )
                .finally(() => {
                    isUpdatingFromAtomRef.current = false;
                });
        }
    }, [queryParams]);

    return null;
}

export const UpdateQueryAtom = atom(
    (get: any) => get(queryParamsAtom),
    (get: any, set: any, action: { operation: "clear" | "update"; data: Record<string, string> }) => {
        if (action.operation === "clear") {
            set(queryParamsAtom, {});
            return;
        }

        const currentQueryParams = get(queryParamsAtom);
        // Apply updates (null/empty => remove)
        const updatedParams = { ...currentQueryParams };
        for (const [key, value] of Object.entries(action.data)) {
            if (value === null || value === undefined || value === "") {
                delete updatedParams[key];
            } else {
                updatedParams[key] = value;
            }
        }

        set(queryParamsAtom, updatedParams);
    },
);

// Usage example:
// const updateQuery = UpdateQueryBatch(true);
// updateQuery({ appName: "myApp", triggerId: "myTrigger" });
// or
// const [queryParams, setQueryParams] = useAtom(queryParamsAtom);
// setQueryParams({ appName: "myApp", triggerId: "myTrigger" }); || setQueryParam((prev) => ({ ...prev, appName: "myApp", triggerId: "myTrigger" }));
