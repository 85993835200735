export function createCustomError(name: string, message = "", nativeError: Error | null = null) {
    class BackendError extends Error {
        constructor() {
            super();
            this.name = name;
            this.message = message;
            this.stack = nativeError ? nativeError.stack : new Error().stack;
        }
    }

    return new BackendError();
}
