import { clsx } from "clsx";

import NoSSR from "react-no-ssr";
import { useCheckUserSessionValidity } from "~/dataProcessor/hooks/user";
import { useTriggerDisabledBanner } from "~/hooks/query/triggers";
import { plainBlackBackgroundCSS, scrollBarStyle } from "../constants/style/common";
import { Sidebar } from "../containers/base/sidebar/Sidebar";
export const SidebarLayoutWrapper = ({ children, isNonSidebarPage }: { children: React.ReactNode; isNonSidebarPage: boolean }) => {
    const { showTriggerDisabledBanner } = useTriggerDisabledBanner();
    useCheckUserSessionValidity({ isSidebarPage: !isNonSidebarPage });

    return isNonSidebarPage ? (
        <>{children}</>
    ) : (
        <div className={clsx("relative flex overflow-x-hidden text-white pt-[100px]", plainBlackBackgroundCSS, scrollBarStyle)}>
            <Sidebar leavePadding={showTriggerDisabledBanner} />
            {children}
        </div>
    );
};

export const SSRWrapper = ({ children, isSEOPath }: { children: React.ReactNode; isSEOPath: boolean }) =>
    isSEOPath ? <>{children}</> : <NoSSR>{children}</NoSSR>;
